<template>
    <div  ref='mainPage'>       
        <div class="notify-msg-div">
            <span style="color:white;background:black">
                {{getCurrWxName()}}
            </span>
            <button @click="sysSet()">
                设置
            </button>
            <button @click="getWxLoginIcon()">
                登录微信
            </button>
            <span class="notify-msg" @click="showFriendNewMsg(msg)"  v-for="(msg,mIndex) in notifyNewMsgsSort" :key="mIndex">
                                                  <el-badge   :value="msg.msgQty" class="item">
                  <span>  {{msg.name2}}</span>
                 </el-badge>
            </span>
                                 
        </div>
        <div v-if='!wxDetailDialog'>             
       <div  class="wx-friend-list-container"  v-for='(wx,wxIndex2) in wxList' :key='wxIndex2'>
        <div>
             <span  style="cursor:pointer;display:inline-block;" @click="showWxDetailWin(wx,wxIndex2)"> {{wx.wxName }}</span>
             &nbsp;
              <span style="color:grey;display:inline-block;">{{ ' '+wx.fromUser}}</span>

        </div>
            <div>                
                <div style="font-size:20px;font-weight:400;padding:5px" > 
                     <div style="display:inline-block;width:150px">
                        <el-input size="mini" style="color:grey" placeholder="搜索" v-model="wx.searchChar" @input="sendSocketSearCh(wx)">                            
                            <!-- <el-button slot="append" icon="el-icon-search"></el-button> -->
                        </el-input>
                    </div>
                    &nbsp;
                    <span>
                        {{wx.friends[wx.currFriendIndex].friendName}}
                    </span>
                   
                   
                </div>
                <div v-if='wx.searchChar.trim().length>0' class='friend-list' style="overflow:auto;width:130px;height:516px">
                     <div v-for='(friend,fdIndex) in getSearchFriends(wx.friends,wx.searchChar)' :key='fdIndex' style=" margin:6px;padding:5px;border:none; background-color: rgb(224, 222, 222);cursor:pointer">
                        <el-badge v-if="getNoReadMsgs(wx.wxName,friend.friendName).length>0"  :value="getNoReadMsgs(wx.wxName,friend.friendName).length" class="item">
                            <span  :style="{'color':wx.currFriendIndex==fdIndex?'blue':'black'}"  @click='selectSearchFriend(wx,friend)' @dblclick="modifyName2Dialog=true;currEditName2Friend=wx.friends[wx.currFriendIndex]">{{friend.name2}}
                            </span> 
                        </el-badge>
                        <span v-else  :style="{'color':wx.currFriendIndex==fdIndex?'blue':'black'}"  @click='selectSearchFriend(wx,friend)' @dblclick="modifyName2Dialog=true;currEditName2Friend=wx.friends[wx.currFriendIndex]">{{friend.name2}}
                        </span>                         
                     </div>
                </div>
                <div v-else class='friend-list' style="display:inline-block;overflow-y:auto;overflow-x:hidden;height:516px; width:160px">
                     <div v-for='(friend,fdIndex) in wx.friends' :key='fdIndex' style=" margin:6px;padding:5px;border:none; background-color: rgb(224, 222, 222);cursor:pointer">
                        <el-badge v-if="getNoReadMsgs(wx.wxName,friend.friendName).length>0"  :value="getNoReadMsgs(wx.wxName,friend.friendName).length" class="item">
                            <span  :style="{'color':wx.currFriendIndex==fdIndex?'blue':'black'}"  @click='selectFriend(wx,fdIndex)' @dblclick="modifyName2Dialog=true;currEditName2Friend=wx.friends[wx.currFriendIndex]">{{friend.name2}}
                            </span> 
                        </el-badge>
                        <span v-else  :style="{'color':wx.currFriendIndex==fdIndex?'blue':'black'}"  @click='selectFriend(wx,fdIndex)' @dblclick="modifyName2Dialog=true;currEditName2Friend=wx.friends[wx.currFriendIndex]">{{friend.name2}}
                        </span>                         
                     </div>
                </div>
                  <div class='msg-main' >
                   <div  :id="wx.wxName"  ref="msgDiv"  style="background:white; height:380px;width:100%; overflow-y: scroll;">
                    <div>
                         <!-- 动态创建 -->
                        <div v-for="(item,index) in friendMsgHandle(wx).msgs" :key="index"  style="position: relative">
                            <div v-if="item.sender==wx.wxName" class="user">
                                <span>{{item.context}}</span>
                            </div>
                            <div v-else class="touser">        
                                    <span :style="{'color':item.noRead?'red':'black'}">{{item.context}}</span>         
                            </div>
                        </div>
                    </div>                 
                </div >               
                <el-input
                    type="textarea"
                    size="5"
                    :autosize="{ minRows: 5, maxRows: 5}"
                    placeholder="Please input"
                    v-model="wx.friends[wx.currFriendIndex].inputMsg"
                    
                    >
                </el-input>
                <div style='text-align:right'>
                     <el-dropdown trigger="click">
                                <span class="el-dropdown-link">
                                    常用语<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown" >
                                    
                                    <el-dropdown-item @click.native="sendFriendMsg(commonYu,wxList[currWxIndex].wxName,wxList[currWxIndex].friends[wxList[currWxIndex].currFriendIndex].friendName,wxList[currWxIndex].fromUser)"   v-for='(commonYu,yIndex) in commonYus' :key='yIndex'>{{commonYu}}</el-dropdown-item>
                                    
                                </el-dropdown-menu>
                            </el-dropdown>
                    <el-button  type="primary"  size="mini" @click="sendFriendMsg(wx.friends[wx.currFriendIndex].inputMsg,wx.wxName,wx.friends[wx.currFriendIndex].friendName,wx.fromUser);wx.friends[wx.currFriendIndex].inputMsg=''">发送</el-button>

                </div> 
            </div>
            </div>
          
           
          
        </div>

        </div>

        <div v-if='wxDetailDialog' class="wx-detail">
             <!-- <el-input size="mini" style="color:grey" placeholder="搜索" v-model="wxList[currWxIndex].searchChar" @input="sendSocketSearCh(wxList[currWxIndex])">                            
                            <el-button slot="append" icon="el-icon-search"></el-button>
             </el-input> -->
            <div class='close-btn' style="text-align:right;padding:3px;font-size:20px;font-weight:400;background:white">
                <span style="padding:5px;cursor:pointer" @click="closeWxWind">X</span>
            </div>
            <div style="">   
                      
                <div   class="wx-friend"  v-for='(friend,fIndex) in currFriends' :key='fIndex'>      
                    <div style="font-size:20px;font-weight:400;padding:5px;width:96%"> 
                        <div style="width:80px;flot:right;display:inline-block;margin-right: 10px;">
                            <el-select size="small " style="color:grey;width:80px;background:rgb(237,237,237)" v-model="friend.language" @change="setFriendLanguage(wxList[currWxIndex].wxName,friend.friendName,friend.language,wxList[currWxIndex].fromUser)" placeholder="Select">
                                <el-option
                                v-for="item in languages"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                       </div >
                        <el-badge v-if="getNoReadMsgs(wxList[currWxIndex].wxName,friend.friendName).length>0"  :value="getNoReadMsgs(wxList[currWxIndex].wxName,friend.friendName).length" class="item">
                        <span class='friend-name'  @dblclick="modifyName2Dialog=true;currEditName2Friend=friend" >{{friend.name2.substring(0,8)}}</span>                  
                        </el-badge>
                        <span  @dblclick="modifyName2Dialog=true;currEditName2Friend=friend" class='friend-name' v-else>{{friend.name2.substring(0,8)}}</span> 
                     
                        <div class='chart-div-icon'> 
                                <i class="el-icon-upload2"  @click="topFriend(friend.friendName)"></i>
                                <i class="el-icon-close"    @click="closeChatWin(friend.friendName)"></i>
                        </div>
                    </div>
                    <div :id='friend.friendName'  style="height:350px;overflow-y: scroll">
                        <div v-for="(item,index) in friendMsg(wxList[currWxIndex].wxName,friend.friendName).msgs" :key="index"  style="position: relative">
                            <div v-if="item.sender==wxList[currWxIndex].wxName" class="user">
                                <!-- <span>{{item.context}}</span> -->
                                <span>{{item.enText}}</span>
                            </div>
                            <div v-else class="touser">        
                                    <span :style="{'color':item.noRead?'red':'black'}">{{item.enText}}
                                        
                                    </span>
                                    
                                    
                                      <div>
                                        <div class='answer-text' v-for='(answer,anIndex) in item.answers' :key='anIndex'>
                                           <span style="margin:0px;padding:2px;"  @click="sendFriendMsg(answer,wxList[currWxIndex].wxName,friend.friendName)">
                                            :{{answer}}
                                            </span> 
                                        </div>
                                    </div> 
                                           
                            </div>
                         </div>
                    </div>
                    <div style="padding:5px">
                            <el-input
                            type="textarea"
                            size="4"
                             :autosize="{ minRows: 4, maxRows: 4}"
                             @keyup.enter="sendFriendMsg(friend.inputMsg,wxList[currWxIndex].wxName,friend.friendName,wxList[currWxIndex].fromUser);friend.inputMsg=''"
                             @focus='setCurrFrendIndex(fIndex)'
                            placeholder="Please input"
                             v-model="friend.inputMsg"
                            >
                        </el-input>
                        <div style='text-align:right'>
                             <span style="text-align:center; color:green; cursor:pointer; display:inline-block;width:120px;padding:5px">
                                 <el-checkbox v-model="friend.autoSend">自动回复</el-checkbox>
                            </span>
                            
                             <!-- <span style="text-align:center; color:green; cursor:pointer; display:inline-block;width:70px;padding:5px">
                                订单
                            </span> -->
                             <el-dropdown trigger="click"	>
                                <span class="el-dropdown-link">
                                    其他<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown" >
                                    
                                    <el-dropdown-item @click.native="getFriendAllMsgs(wxList[currWxIndex].wxName,friend.friendName,wxList[currWxIndex].fromUser)">获取全部记录</el-dropdown-item>
                                    <el-dropdown-item @click.native="getFriendAllMsgs(wxList[currWxIndex].wxName,friend.friendName,wxList[currWxIndex].fromUser)">机器学习</el-dropdown-item>
                                    
                                </el-dropdown-menu>
                           </el-dropdown>

                            <el-dropdown trigger="click">
                                <span class="el-dropdown-link">
                                    常用语<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown" >
                                    
                                    <el-dropdown-item @click.native="sendFriendMsg(commonYu,wxList[currWxIndex].wxName,friend.friendName,wxList[currWxIndex].fromUser)"   v-for='(commonYu,yIndex) in commonYus' :key='yIndex'>{{commonYu}}</el-dropdown-item>
                                    
                                </el-dropdown-menu>
                            </el-dropdown>
                           <el-button  @click="sendFriendMsg(friend.inputMsg,wxList[currWxIndex].wxName,friend.friendName,wxList[currWxIndex].fromUser,wxList[currWxIndex].fromUser);friend.inputMsg=''"  type="primary"  size="mini" >发送</el-button>
 
                        
                    </div>
                       </div>
                </div>
                                              

             
              
            </div>

        </div>

<el-dialog
  title="初始化中..."
  :visible.sync="loginIconDialog"
  width="80%"
  :before-close="handleClose">
  <div>
            <span v-for='(img,iIndex) in loginImgs' :key="iIndex">
                      <img :src="img" alt="">
            </span>
           
        </div>
</el-dialog>
<el-dialog
  title="系统设置"
  :visible.sync="setIconDialog"
  width="80%"
  :before-close="handleClose">
  <div >
          <div class='set-div' v-for='(item,itemIndex) in dataServers' :key='itemIndex'>           
           <span >   
             <i v-if="item.socketId"  class="el-icon-success" style="color:green"></i>
             <i v-else  class="el-icon-error" style="color:red"></i>  
           </span>

           <!-- <span>
            {{item.connectType}}
           </span> -->
           <span style="width:200px">
            {{item.userName}}
           </span>        
          
            <el-select v-model="item.toUser" placeholder="Select">
                    <el-option
                        v-for="item in webUseList"
                        :key="item.userName"
                        :label="item.userName"
                        :value="item.userName">
                    </el-option>
            </el-select>          
          </div>
           
        </div>
         <span slot="footer" class="dialog-footer">
    <el-button @click="setIconDialog = false">取消</el-button>
    <el-button type="primary" @click="confirmSet">确定</el-button>
  </span>
</el-dialog>

<el-dialog
  title="修改备注"
  :visible.sync="modifyName2Dialog"
  width="30%"
  :before-close="handleClose">
  <div >
     <el-input v-model="currEditName2Friend.name2">

    </el-input>
        
           
        </div>
         <span slot="footer" class="dialog-footer">
    <!-- <el-button @click="setIconDialog = false">取消</el-button> -->
    <el-button type="primary" @click="modifyName2Dialog=false">确定</el-button>
  </span>
</el-dialog>




      

    </div>
</template>

<script>
import io from 'socket.io-client';		//引入socket.io-client
import pako from 'pako';
export default {
    data(){
        return {
            searchTimer:null,
            userName:'',
            dataServers:[],
            currEditName2Friend:{
                name2:''
            },
           
            socketuserlist:[
                      
                         {
                            connectType:'data', //web
                            userName:'SKY-20220102IZY',                           
                            toUser:'',
                            socketId:'',
                            status:false,
                        },
                        {
                            connectType:'web', //web
                            userName:'zjp',                           
                            toUser:'',
                            socketId:'',
                            status:false,
                        },
                         {
                            connectType:'web', //web
                            userName:'kld',                           
                            toUser:'',
                            socketId:'',
                            status:false,
                        },
                       
                
            ], 
           
            loginIconDialog:false,
            setIconDialog:false,
            modifyName2Dialog:false,
            loginImgs:[],
            notifyNewMsgs:[
            //     {
            //     msgTime:'20240607120301',
            //     wxName:'test',
            //     friendName:'大山大河',
            //     name2:'大山大河',
            //     msgQty:1,
            //     readed:false
            //   } ,
            //   {
            //     msgTime:'20240607120302',
            //     wxName:'test',
            //     friendName:'大树',
            //     name2:'大树',
            //     msgQty:2,
            //     readed:false
            //   } 
            ],
            commonYus:[
                "你好，我非常高兴能在这里遇到你。你对这个聊天有什么期待呢？",
                "我很高兴看到你这么开心。",
                "问候与寒暄早上好！晚上好！你好！好久不见。最近好吗？你看起来很不错。",
                "我最近有些烦，你能陪我聊聊天吗？",
                "嗨，你好！很高兴能和你聊天。今天你有什么安排呢？",
                "嗨！你好呀！最近怎么样？",
                "你在干吗呢？有空一起出来喝杯咖啡不？",
                "你看起来很累，需要休息一下吗？我可以帮你按摩肩膀。",
                "不客气，我很高兴和你聊天。祝你有个愉快的旅行！",
                "喂喂喂！大忙人，抽空陪我说说话呗。",
                "非常感谢您抽出时间与我聊天。",
                "我很高兴能够认识你。"
            ],            
            languages: [{
          value: 'zh',
          label: '中文'
        }, {
          value: 'en',
          label: '英语'
        }, {
          value: 'spa',
          label: '西班牙语'
        }, {
          value: 'jp',
          label: '日语'
        }, {
          value: 'th',
          label: '泰语'
        }, {
          value: 'kor',
          label: '韩语'
        }, {
          value: 'pt',
          label: '葡萄牙语'
        }, {
          value: 'cht',
          label: '中文(繁体)'
        }, {
          value: 'vie',
          label: '越南语'
        }],

           
            currWxIndex:0,
            wxDetailDialog:false,
           
            wxList:[
                // {
                //     wxName:'test',
                //     fromUser:'',
                //     currFriendIndex:0,
                //      searchChar:'',
                    
                //     friends:[
                //         {
                //             friendName:'大山大河',
                //             inputMsg:'',
                //             language:'zh',
                //             autoSend:false,
                //             viewStatus:false,
                //             viewCloseStatus:false,
                //             name2:'大山大河',
                           
                            
                //         },
                //          {
                //             friendName:'大树',
                //             inputMsg:'',
                //             language:'zh',
                //             autoSend:false,
                //             viewStatus:false,
                //             viewCloseStatus:false,
                //             name2:'大树',
                           
                //         }
                //     ]                  
                
                // }
            ],
            socketId:null,
            allMsgs:[
                {
                        wxName:'test',                        
                        friends:[{
                            frdName:'大山大河',
                            msgs:[
                                {
                                    msgId:'1111',
            'time':'2024年5月7日 1:48',
            'sender':'心如止水',
            'context':'你好',
            'enText':'你好',
            'answers':["你好，今天天气不错","你在哪里?","今天忙吗?","好久没见你啊！"],            
            noRead:true
        },                                {
             msgId:'22222',
            'time':'2024年5月7日 2:48',
            'sender':'大山大河',
            'context':'你今天的计划是什么',
            'enText':'你今天的计划是什么',
            'answers':["我没有具体计划，你有什么建议吗？"],
            noRead:true
        }
                            ],
                              noReadMsgs:1,
                        }],                        
                 }
            ]
        }
    },
    computed:{
        currFriends(){
            console.log('currFriends')
           let list =   this.wxList[this.currWxIndex].friends.filter(item=>{
                return !item.viewCloseStatus
           })
           return list

        },
      
      webUseList(){
       let list  = this.socketuserlist.filter(item=>{
                return item.connectType=='web'
            })
            return list
           
      },
       notifyNewMsgsSort(){
           let data = this.notifyNewMsgs.sort((a,b)=>{
            return b.msgTime - a.msgTime
           })
           return data
       }
    },
    methods:{
        sendSocketSearCh(wx){
            if(wx.searchChar.trim().length<1){
                return
            }
            if(this.searchTimer !=null){
                clearInterval(this.searchTimer)
            }
            this.searchTimer = setTimeout(()=>{
                console.log('socket search...')
                 let info =  {'tag': '#searchfriend#', 'toUser': wx.fromUser, 
                        'wxName':wx.wxName,                     
                         'fromUser':this.userName,
                         'searchChar':wx.searchChar

             }
             this.socketId.emit('submitdata', info);
                
            },3000)


            

        },
        getSearchFriends(friends,searchChar){

            let list = friends.filter(item=>{
                let flag = false
                let fd1 = item.friendName.indexOf(searchChar.trim())
                let fd2 = item.name2.indexOf(searchChar.trim())
                if(fd1!=-1||fd2!=-1){
                  flag = true
                }
                return flag
            })
            return list

        },
        setCurrFrendIndex(index){
            this.wxList[this.currWxIndex].currFriendIndex=index
            console.log('this.wxList[currWxIndex]',this.wxList[this.currWxIndex])

        },
        getCurrWxName(){
            let str = ''
            if(this.wxList.length>0){
                str = this.wxList[this.currWxIndex].wxName

            }
            return str
           

        },
        handleEnterPress(){
            console.log('handleEnterPress')
        },
        
         topFriend(friendName){
            let frds = this.wxList[this.currWxIndex].friends
            let fdIndex = frds.findIndex(item=>{
                 return item.friendName == friendName
            })
            console.log('closeChatWin')
            
            let frd = frds.splice(fdIndex, 1)[0]; // 删除第二个元素并保存
            // frds.push(frd); // 将保存的元素添加到数组末尾
            frds.splice(0,0,frd)

        },
        closeChatWin(friendName){
            console.log('closeChatWin')
             let frds = this.wxList[this.currWxIndex].friends
            let fdIndex = frds.findIndex(item=>{
                return item.friendName == friendName
            })
            if(fdIndex !=-1){
                frds[fdIndex].viewCloseStatus = true
                this.wxList = JSON.parse(JSON.stringify(this.wxList))
            }
           
            // frds[fdIndex].viewStatus = false

            
            
            // let frd = frds.splice(fdIndex, 1)[0]; // 删除第二个元素并保存
            // frds.push(frd); // 将保存的元素添加到数组末尾

        },
        getSocketStatus(hostName){
            let online = false
            let fdIndex = this.socketuserlist.findIndex(item=>{
                return item.userName == hostName
            })
            if(fdIndex !=-1){
                if( this.socketuserlist[fdIndex].socketId){
                    online = true
                }

            }

            return online

        },
        confirmSet(){
            debugger
            console.log('confirmSet')
            this.setIconDialog = false
            for(let i=0;i<this.dataServers.length;i++){
                let fdIndex = this.socketuserlist.findIndex(item=>{
                    return item.userName == this.dataServers[i].userName
                })
                if(fdIndex !=-1){
                   this.socketuserlist[fdIndex].toUser = this.dataServers[i].toUser
                }
            }
            this.socketId.emit('updatasysset',
                  this.socketuserlist
                );		//推送用户账号给后端socket		

        },
         handleClose(done) {
         done()
      },
        getFriendAllMsgs(wxName,friendName,toUser){
            let info =  {'tag': '#getallmsg#', 'toUser': toUser, 
                        'wxName':wxName,
                        'friend':friendName,
                         'fromUser':this.userName

             }
             this.socketId.emit('submitdata', info);

        },
        sysSet(){
            console.log('sysSet')
            
            let list = this.socketuserlist.filter(item=>{
                return item.connectType=='data'
            })
        
            this.dataServers  = Array.from(list);
            //  this.dataServers = [...list]
            this.setIconDialog = true
        },

         getWxLoginIcon(){
            this.loginIconDialog = true
            this.loginImgs = []
            let socketMachins = this.socketuserlist.filter(item=>{
                return item.toUser = this.userName 
            })
            for(let i=0;i<socketMachins.length;i++){
                  let info =  {'tag': '#getloginicon#', 'toUser': socketMachins[i].userName, 
                        'wxName':"",
                        'friend':"",
                         'fromUser':this.userName
                 }
                 this.socketId.emit('submitdata', info);
            }
            

        },

        getNoReadMsgs(wxName,friendName){
            let noReadMsgs = []
            let wxIndex = this.allMsgs.findIndex(item=>{
                return wxName == item.wxName
              })
            if(wxIndex !=-1){
            let wxFriends = this.allMsgs[wxIndex].friends
            let frdIndex = wxFriends.findIndex(item=>{
                return item.frdName == friendName 
            })
            if(frdIndex !=-1){
                let frdMsgs = wxFriends[frdIndex].msgs
                noReadMsgs = frdMsgs.filter(item=>{
                   return item.noRead
                })
            }

            }
            // console.log('noReadMsgs',noReadMsgs.length)
           
            return noReadMsgs


        },

        setMsgReadedStatus(msgIds){
            
              setTimeout(()=>{
                let clearFriends = []
                for(let m=0;m<msgIds.length;m++){
                    for( let a=0;a<this.allMsgs.length;a++){
                        let wxFrieds = this.allMsgs[a].friends
                        let wxName = this.allMsgs[a].wxName
                        for(let w=0;w<wxFrieds.length;w++){
                            let wxMsgs = wxFrieds[w].msgs
                            let friendName = wxFrieds[w].frdName
                            for(let w=0;w<wxMsgs.length;w++){
                                if(wxMsgs[w].msgId==msgIds[m]){
                                    wxMsgs[w].noRead = false
                                    let fdI = clearFriends.findIndex(item=>{
                                        return item.wxName == wxName && item.friendName ==friendName
                                    })
                                    if(fdI == -1){
                                        clearFriends.push({
                                            wxName,
                                            friendName

                                        })
                                    }

                                }
                            }

                        }
                        
                    }
                  }

                  
//清除肖息提示

                  for(let f=0;f<clearFriends.length;f++){
                        let xIndex = this.notifyNewMsgs.findIndex(item=>{
                            return item.wxName == clearFriends[f].wxName && item.friendName == clearFriends[f].friendName
                        })
                        if(xIndex !=-1){
                                this.notifyNewMsgs.splice(xIndex,1)
                        }
                  }






                },50*1000)
                 this.allMsgs = JSON.parse(JSON.stringify(this.allMsgs))
                 this.$forceUpdate()
            
        },
        closeWxWind(){
            this.wxDetailDialog=false
            let friends = this.wxList[this.currWxIndex].friends
            for(let f=0;f<friends.length;f++){
               friends[f].viewStatus = false
            }
         },
         showFriendNewMsg(msg){
            let wxIndex = this.wxList.findIndex(item=>{
                return item.wxName == msg.wxName
            })
            if(wxIndex !=-1){                 
                //移出新信息提示队列
                let notifyIndex = this.notifyNewMsgs.findIndex(item=>{
                    return item.wxName == msg.wxName && item.friendName == msg.friendName
                })             
                if(notifyIndex !=-1){
                    this.notifyNewMsgs.splice(notifyIndex,1)
                }
                //把好友移至最前明
                let friends = this.wxList[wxIndex].friends
                let frdIndex = friends.findIndex(item=>{
                    return item.friendName == msg.friendName
                })
                const element = friends.splice(frdIndex, 1)[0];
                friends.splice(0, 0, element);
                this.showWxDetailWin(this.wxList[wxIndex],wxIndex)  
             }            
              
         },
          showWxDetailWin(wx,wxIndex){
           this.currWxIndex=wxIndex;
           this.wxDetailDialog=true
           let friends = wx.friends
           let noReadMsgs = []
           for(let f=0;f<friends.length;f++){
              friends[f].viewStatus = true
              let noMsgs = this.getNoReadMsgs(wx.wxName,friends[f].friendName)
              noReadMsgs = noReadMsgs.concat(noMsgs)
           }
             let noReadIds = []
              for(let n=0;n<noReadMsgs.length;n++){
                 noReadIds.push(noReadMsgs[n].msgId)
              }  
              if(noReadIds.length>0){
                 this.setMsgReadedStatus(noReadIds)
              }  

               // 在 mounted 钩子中为每个 input 绑定事件监听器

             
              
        },
        handleCommand(command) {
        this.$message('click on item ' + command);
      },
        
        setFriendLanguage(wxName,friendName,language,toUser){            
             let info =  {'tag': '#setfriendlan#', 'toUser': toUser, 
                        'wxName':wxName,
                        'friend':friendName,
                        'language':language,
                         'fromUser':this.userName
             }
             this.socketId.emit('submitdata', info);
        },
     

        sendFriendMsg(msg,wxName,toFriend,toUser){
            console.log('ssss')
             let info =  {'tag': '#replayMsg#', 'user': '大山大河', 'toUser': toUser, 
                        'wxName':wxName,                     
                        'toFriend':toFriend,
                        'msg':msg,
                        'fromUser':this.userName
             }
             console.log('info',info)
             this.socketId.emit('submitdata', info);		
        },
        selectSearchFriend(wx,friend){
            // let fdName = friend.friendName
            let fdName2 = friend.name2
            let index = wx.friends.findIndex(item=>{
                return fdName2==item.name2
            })
            if(index!=-1){
                this.selectFriend(wx,index)
                wx.friends[index].viewCloseStatus = false
                
            }

           
            wx.searchChar = ""

        },
        selectFriend(wx,index){
              wx.friends[wx.currFriendIndex].viewStatus = false
              wx.friends[index].viewCloseStatus = false
              wx.currFriendIndex=index              
              //
              let noReadMsgs = this.getNoReadMsgs(wx.wxName,wx.friends[index].friendName)
              let noReadIds = []
              for(let n=0;n<noReadMsgs.length;n++){
                 noReadIds.push(noReadMsgs[n].msgId)
              }  
              if(noReadIds.length>0){
                 this.setMsgReadedStatus(noReadIds)
              }  
              
              wx.searchChar = ""
              
              
            //    let frds = this.wxList[this.currWxIndex].friends
            // let fdIndex = frds.findIndex(item=>{
            //      return item.friendName == friendName
            // })
            // console.log('closeChatWin')
            
            // let frd = frds.splice(fdIndex, 1)[0]; // 删除第二个元素并保存
            
            // frds.splice(0,0,frd)
              
              
              this.$nextTick(() => {
                   // 获取滚动条所在的元素
var msgDiv = document.getElementById(wx.wxName);

// 将滚动条移动到最底部
msgDiv.scrollTop = msgDiv.scrollHeight;
    });
           
  

//               let chatRoom =  document.querySelector();
//   // 滚动到容器底部
//   chatRoom.scrollTop = chatRoom.scrollHeight;

        },
        friendMsgHandle(wx){
            let wxName = wx.wxName
            let msgs = []
            let index = wx.currFriendIndex
            if(index >=0){
                msgs = this.friendMsg(wxName,wx.friends[index].friendName)
            }
            return msgs

        },
        friendMsg(wxName,friend){
            
            let msgs = {
                            frdName:'',
                            msgs:[],
                            noReadMsgs:[]
                       }
            // let wxName = wx.wxName
            // let friend = wx.currFriend
            let wxDetail = {}
            let fdIndex = this.allMsgs.findIndex(item=>{
                return item.wxName == wxName
            })
            if(fdIndex !=-1){
                wxDetail = this.allMsgs[fdIndex]
                let friendIndex = wxDetail.friends.findIndex(item=>{
                    return item.frdName == friend
                })
                if(friendIndex !=-1){
                    msgs = wxDetail.friends[friendIndex]
                }

            }
            if(msgs.length>0){
                 console.log('msgs',msgs)

            }
           
            return msgs

        },
        openSocket(){
            
            this.socketId  = io('http://42.192.47.53:3000/');		//路径
            //  this.socketId  = io('http://localhost:3000/');		//路径
            let that = this
            let loginUserNmae = this.userName
			
			// console.log('初始化：socket', socket);
			this.socketId.on('connect', (data) => {
				
				this.socketId.emit('join',
                 {
        connectType:'web', //web
        userName:loginUserNmae,       
        toUser:'',
        socketId:''
    }
                );		//推送用户账号给后端socket
			});

			this.socketId.on('close', () => {
				console.log('socket连接关闭');
			});


            this.socketId.on('submitdata', (msg) =>{
                
                let tag = msg.tag
                switch(tag){
                    case "#socketuserlist#":
                        console.log('#socketuserlist#',msg.data)
                        let newList = msg.data
                        for(let i=0;i<newList.length;i++){
                            let fdIndex = that.socketuserlist.findIndex(item=>{
                                return  item.userName == newList[i].userName
                            })
                            if(fdIndex !=-1){
                                //  that.socketuserlist[fdIndex].socketId = newList[i].socketId
                                 that.socketuserlist[fdIndex] = newList[i]
                                 
                            }
                            else
                            {
                                that.socketuserlist.push(newList[i])
                               
                            }
                        }
                        console.log('that.socketuserlist',that.socketuserlist)
                        // that.socketuserlist = msg.data
                        break
                    case '#wxuserlist#':
                        let wxName2 = msg.wxName
                        let fdIndex = that.wxList.findIndex(item=>{
                            return item.wxName == wxName2
                        })
                        if(fdIndex!=-1){
                           
                            for(let m=0;m<msg.context.length;m++){
                                let fdMI = that.wxList[fdIndex].friends.findIndex(item=>{
                                    return item.friendName==msg.context[m].friendName
                                })
                                if(fdMI !=-1){
                                    // msg.context[m].autoSend = that.wxList[fdIndex].friends[fdMI].autoSend
                                    // msg.context[m].viewStatus = that.wxList[fdIndex].friends[fdMI].viewStatus
                                   
                                }
                                else
                                {
                                    // msg.context[m].name2 = msg.context[m].friendName                                    
                                    that.wxList[fdIndex].friends.push(msg.context[m])
                                }
                            }
                            //  that.wxList[fdIndex].friends = msg.context
                        }
                        else
                        {
                            that.wxList.push({
                                wxName:wxName2,
                                fromUser:msg.fromUser,
                                friends:msg.context,
                                currFriendIndex:0,  
                                language:'zh',  
                                searchChar:''
                            })
                        }
                        //   that.wxList = JSON.parse(JSON.stringify(that.wxList))
                        //   that.$forceUpdate() 
                          
                        break
                    case "#wxloginicon#":
                         console.log('wxloginicon',msg)
                        const arrayBuffer = event.data;
                        const compressedData = new Uint8Array(arrayBuffer);
                        const inflatedData = pako.inflate(compressedData);
                        const blob = new Blob([inflatedData], { type: 'image/png' });
                        const imageUrl = URL.createObjectURL(blob);
                        console.log('inflatedData',inflatedData)
                        that.loginImgs.push(imageUrl)
                        break
                    case '#msgall#':                        
                        var fdIndex3 = that.allMsgs.findIndex(item=>{
                            return item.wxName == msg.wxName
                        })
                        if(fdIndex3 !=-1){
                            let friendIndex = that.allMsgs[fdIndex3].friends.findIndex(item=>{
                                return item.frdName==msg.user
                            })
                            if(friendIndex !=-1){
                                that.allMsgs[fdIndex3].friends[friendIndex].msgs = []

                            }
                        }
                    
                    
                         

                    case '#newwxmsg#':
                       {
                        console.log('submitdata',msg)
                        let wxDetailName = msg.wxName
                        let friendName = msg.user
                        let chatMsgs = msg.newMsgs
                        let allMsgs = that.allMsgs
                        let name2 = ''
                        let fdIndex2 = allMsgs.findIndex(item=>{
                            return item.wxName == wxDetailName
                        })
                        if(fdIndex2 !=-1){
                            let friendIndex6 = allMsgs[fdIndex2].friends.findIndex(item=>{
                                return item.frdName==friendName
                            })
                            if(friendIndex6 !=-1){
                                that.allMsgs[fdIndex2].friends[friendIndex6].msgs = that.allMsgs[fdIndex2].friends[friendIndex6].msgs.concat(chatMsgs)

                            }
                            else
                            {
                                that.allMsgs[fdIndex2].friends.push({
                                    frdName:friendName,
                                    msgs:chatMsgs,
                                    noReadMsgs:[]
                                })

                            }
                        }
                        else
                        {
                            that.allMsgs.push({
                                wxName:wxDetailName,
                                friends:[{
                                    frdName:friendName,
                                    msgs:chatMsgs,
                                    noReadMsgs:[]
                               }] 
                            }                            
                            )

                        }
                         
                           
                      
                         console.log('that.allMsgs',that.allMsgs)                        
                         
                        
                        let fdIndex5 = that.wxList.findIndex(item=>{
                                    return item.wxName == wxDetailName
                                })

                        if(fdIndex5!=-1){
                            let setReadedMsgIds = []
                        let wxFriends = that.wxList[fdIndex5].friends
                                                    let frdViewStatus = false
                           
                            let wxFIndex = wxFriends.findIndex(item=>{
                                return item.friendName == friendName
                            })
                            if(wxFIndex !=-1){
                                frdViewStatus = wxFriends[wxFIndex].viewStatus
                                name2 =  wxFriends[wxFIndex].name2

                            }
                             if(frdViewStatus){
                         for(let i=0;i<chatMsgs.length;i++){

                           
                                setReadedMsgIds.push(chatMsgs[i].msgId)
                          
                         }
                                                     } 
                      
                         that.setMsgReadedStatus(setReadedMsgIds)

                        }

                        

                        //   自动回复
                         if(chatMsgs.length>0)
                         {
                            let lastMsg = chatMsgs[chatMsgs.length-1]
                            if(lastMsg.sender != wxDetailName && lastMsg.answers.length>0){
                               let fdIndex3 = that.wxList.findIndex(item=>{
                                    return item.wxName == wxDetailName
                                })
                                if(fdIndex3!=-1){                                
                                     let fdMI = that.wxList[fdIndex3].friends.findIndex(item=>{
                                            return item.friendName== friendName
                                        })
                                        if(fdMI !=-1){
                                            if(that.wxList[fdIndex3].friends[fdMI].autoSend){
                                                that.sendFriendMsg(lastMsg.answers[0],wxDetailName,friendName)

                                            }
                                            
                                        }
                                   
                                }
                                
                            }
                         }
                         
                         //更新提示栏
                         for(let c=0;c<chatMsgs.length;c++){
                            let nowDate = new Date()
                            let fdNotifyIndex = that.notifyNewMsgs.findIndex(item=>{
                                return item.wxName == msg.wxName && item.friendName == msg.user
                            })
                            if(fdNotifyIndex !=-1){
                                that.notifyNewMsgs[fdNotifyIndex].msgTime = nowDate
                                that.notifyNewMsgs[fdNotifyIndex].msgQty++
                                
                            }
                            else
                            {
                               
                                that.notifyNewMsgs.push(
                                    {
                                        msgTime:nowDate,
                                        wxName:msg.wxName,
                                        friendName:msg.user,
                                        msgQty:1,
                                        readed:false,
                                        name2:name2
                                    }
                                )
                            }
                         }                         

                        that.allMsgs = JSON.parse(JSON.stringify(that.allMsgs))
                        that.$forceUpdate() 
                        that.$nextTick(() => {
                            // 获取滚动条所在的元素
                            var msgDiv = document.getElementById(friendName);

                           try {
                             // 将滚动条移动到最底部
                            msgDiv.scrollTop = msgDiv.scrollHeight;
                            
                           } catch (error) {
                              console.log(error)
                           }
                          
                        });                      
                        break                        
                       } 
                       


                }

            })

		
        },
        timerFunc(){

        }
    },
    mounted(){
        console.log('mounted')
       
        this.userName = localStorage.getItem('userName')
         this.openSocket()

 


          this.$refs.mainPage.addEventListener('keyup', (event) => {
            if (event.key === 'Enter') {
                
                let wx = this.wxList[this.currWxIndex]
                let fIndex = wx.currFriendIndex
                let friend = wx.friends[fIndex]
                 console.log('sssffsfd')
                this.sendFriendMsg(friend.inputMsg,wx.wxName,friend.friendName,wx.fromUser);
                friend.inputMsg=''
               
            }
            });
        
       
        // 启动定时器
        // setInterval(()=>{
        //     this.timerFunc()
        // },1000)
    }

}
</script>

<style scoped>
.wx-friend-list-container{
    /* border:1px solid rgb(156, 151, 151); */
    overflow: hidden;
    margin:10px;
    width:500px;
    height: 650px;
    display: inline-block; 
    background: rgb(245,245,245); 

}
.wx-friend{
    /* border:1px solid rgb(156, 151, 151); */
    margin:10px;
    width:330px;
    height: 560px;
    overflow: auto;
    display: inline-block;
    padding: 3px;
    background-color: rgb(240, 240, 240);
    

}
.wx-friend-list-container>div{
     /* display: inline-block;
     border:1px solid rgb(184, 178, 178); */
     margin:2px;
     /* height: 100%; */
     vertical-align: top;
      /* background-color: rgb(224, 222, 222); */
       background-color: rgb(237, 237, 237);
      
}
.user{
      position: relative;
      float: right;
      margin-right: 3px;
      padding: 1px;
      box-sizing: border-box;
      border-radius: 3px;
      /* width:55%;  */
      width: 80%;
      text-align: right;
       /* background-color: rgb(237, 237, 237);; */

      /* background-color: rgb(116, 228, 116); */
}
.touser{
      position: relative;
      float: left;
      margin-right: 3px;
      padding: 1px;
      box-sizing: border-box;
      border-radius: 3px;
      /* width:55%;  */
      width: 80%;
      /* background-color: rgb(237, 237, 237); */
      /* background-color: rgb(116, 228, 116); */
}
.user span{
      display: inline-block;
      border-radius: 3px;
      padding: 6px;
      margin:3px;
      background-color: rgb(116, 228, 116);

}

.touser span{
      display: inline-block;
      border-radius: 3px;
        padding: 6px;
        margin:3px;
        background-color: white;   
}
.wx-detail{
    position: fixed;
    top:45px;
    min-height:680px;
    max-height: 680px;
    overflow: auto;
    margin: 0px;
    width: 100%;
    background: white;
    /* background: rgb(237, 237, 237); */
}

.close-btn{
    position: absolute;
    width:50px;
    right:10px;
}
.answer-text span{
    color:rgba(71, 72, 74, 0.463);
     background: rgb(237, 237, 237);
     cursor: pointer;
}
.friend-name{
    display: inline-block;    
    width:150px;
    font-size: 18px;
    /* overflow: hidden; */
    margin-right: 16px;
}
.notify-msg-div{
    background: rgb(245, 245, 245);
    padding:8px;
}
.notify-msg-div .notify-msg{
    display: inline-block;
    margin-left: 20px;
    cursor: pointer;
}

.set-div>span{
    display: inline-block;
    width:45px;
}

.chart-div-icon{
    display:inline-block;
    width:50px;
    /* float:right;     */
}
.msg-main{
    display: inline-block;
    width:330px;
    height:100%;
    /* border:1px solid grey */
}



</style>